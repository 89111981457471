<template>
    <!-- add lg:flex to show -->
    <div v-if="isSignedIn" class="hidden flex-column h-full z-5 bg-white ">
        <div class="overflow-y-hidden h-full p-3">
          <NuxtLink to="/" class="flex align-items-center justify-content-center text-600 cursor-pointer p-ripple gap-1" >
            <img src="/img/logo-small.png" alt="Adaline Research" style="height: 32px" />
          </NuxtLink>
          <div class="flex flex-column h-full">
            <ul class="list-none p-0 m-0 mt-8 overflow-hidden">
              <li v-for="(item, i) in menuItems">
                <NuxtLink 
                  v-ripple 
                  :to="item.path"
                  class="no-underline flex align-items-center cursor-pointer p-2 py-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple"
                  :class="item.path === route.path ? 'highlighted' : ''"
                >
                  <i v-if="item.icon" :class="`pi pi-${item.icon} mr-2`"></i>
                  <span class="font-medium">{{item.title}}</span>
                </NuxtLink>
              </li>
            </ul>
          </div>
        </div>
    </div>
</template>

<script setup lang="ts">
  import { useUser } from 'vue-clerk';
  import { SignInButton, UserButton, useAuth, SignOutButton } from 'vue-clerk'

  const { isSignedIn } = useAuth()
  const route = useRoute()

  definePageMeta({
    middleware: ['auth'],
  });

  const { user } = useUser();

  const menuItems = ref([
    {
      icon: 'qrcode',
      title: 'Dashboard',
      path: '/',
    },
    {
      icon: 'search',
      title: 'Companies',
      path: '/company/search',
    },
    {
      icon: 'bookmark',
      title: 'My Lists',
      path: '/company/lists',
    },
  ]);
</script>
