<style>
.menu-button {
  width: 2rem !important;
  height: 2rem !important;
}
.menu-button span {
  font-size: 0.875rem !important;
}
.logo-type {
  padding: 0;
  margin: 0;
  width: 2rem !important;
  height: 2rem !important;
}
.cl-userButtonAvatarBox {
  width: 2rem !important;
  height: 2rem !important;
}
.badge-container {
  position: relative;
  height: 2rem;
  border-radius: 1rem;
  background-color: white; /* Lighter shade of purple */
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.875rem;
  line-height: 0.875rem;
  color: var(--primary-color);
  font-weight: 500;
  text-align: center;
}
.badge-box {
  position: relative;
  height: 2rem;
  border-radius: 1rem;
  background-color: white; /* Lighter shade of purple */
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.7rem;
  color: var(--primary-color);
  font-weight: 500;
  box-sizing: border-box;
}
.badge-container span {
  padding-left: 0.4rem;
  padding-right: 0.875rem;
  text-align: left;
}

</style>
<template>
    <div v-if="isSignedIn" class="flex w-full bg-white surface-border z-5 justify-content-between p-2 pr-4 pl-4" style="box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;">
      <NuxtLink to="/" class="flex justify-content-start align-items-center">
        <!-- Add lg:hidden to hide logo in large screens-->
        <div class="badge-container">
          <div class="badge-box">
            <img class="logo-type" src="/img/download.png" alt="Adaline Research Logo" />
            <div class="flex flex-column">
              <span>powered by</span>
              <span>Adaline Research</span>
            </div>
          </div>
        </div>
      </NuxtLink>

      <div class="flex justify-content-flex-end align-items-center">
        <div class="ml-2">
          <UserButton class="logo-type" v-if="isSignedIn" />
        </div>
      </div>
    </div>
</template>

<script setup lang="ts">
  import { useUser } from 'vue-clerk';
  import { SignInButton, UserButton, useAuth, SignOutButton, OrganizationSwitcher } from 'vue-clerk'

  const { isSignedIn, claims } = useAuth()

  definePageMeta({
    middleware: ['auth'],
  });

  const { user } = useUser();

</script>
