<template>
  <div v-if="!user" class="fixed w-screen h-screen z-5 bg-white"/>

  <div class="flex">
    <aside class="h-screen sticky top-0 z-4">
      <SideBarMenu/>
    </aside>
    <div class="flex flex-column w-full">
      <aside class="sticky top-0 z-4">
        <TopBarMenu/>
      </aside>

      <main class="flex justify-content-center h-full z-4" style="background-color: rgb(245 245 245);">
        <div class="flex p-3 w-full flex-grow-1 flex-shrink-1 overflow-hidden height-auto">
          <slot />
        </div>
      </main>
    </div>
  </div>
    <ConfirmDialog>
      <template #container="{ message, acceptCallback, rejectCallback }">
          <div class="flex flex-column align-items-center p-5 surface-overlay border-round">
              <div class="border-circle bg-danger inline-flex justify-content-center align-items-center h-6rem w-6rem mt-8">
                <i :class="`${message.icon} text-5xl`"></i>
              </div>
              <span class="font-bold text-2xl block mb-2 mt-4">{{ message.header }}</span>
              <p class="mb-0">{{ message.message }}</p>
              <div class="flex align-items-center gap-2 mt-4">
                  <Button :class="message.acceptClass" :label="message.acceptLabel" @click="acceptCallback"></Button>
                  <Button :class="message.rejectClass" :label="message.rejectLabel" outlined @click="rejectCallback"></Button>
              </div>
          </div>
      </template>
    </ConfirmDialog>
</template>

<script setup lang="ts">
  import { useUser } from 'vue-clerk';

  const { user } = useUser();
</script>

<style>
  body { margin: 0 !important; }
  p {
      display: block;
      margin-block-start: 0em;
      margin-block-end: 0em;
      margin-inline-start: 0px;
      margin-inline-end: 0px;
  }

  .p-button.p-button-danger {
      color: #fff;
      background: var(--red-700);
      border: 1px solid var(--red-700);
  }
  .bg-danger {
      color: #fff;
      background: var(--red-700);
  }
</style>
